export const appearanceKey = [
  {
    name: "None",
    key: 0
  },
  {
    name: "The Phantom Menace",
    key: 1
  },
  {
    name: "Attack of the Clones",
    key: 2
  },
  {
    name: "Revenge of the Sith",
    key: 3
  },
  {
    name: "A New Hope",
    key: 4
  },
  {
    name: "The Empire Strikes Back",
    key: 5
  },
  {
    name: "Return of the Jedi",
    key: 6
  },
  {
    name: "The Force Awakens",
    key: 7
  },
  {
    name: "The Last Jedi",
    key: 8
  },
  {
    name: "The Rise of Skywalker",
    key: 9
  },
  {
    name: "Rogue One",
    key: 10
  },
  {
    name: "Solo",
    key: 11
  },
  {
    name: "Clone Wars",
    key: 12
  },
  {
    name: "Rebels",
    key: 13
  },
  {
    name: "Resistance",
    key: 14
  },
  {
    name: "Video Game",
    key: 15
  },
  {
    name: "The Mandalorian",
    key: 16
  },
  {
    name: "The High Republic",
    key: 17
  },
  {
    name: "Comic/Other",
    key: 18
  },
  {
    name: "Roleplaying",
    key: 19
  },
  {
    name: "Kenobi",
    key: 20
  },
  {
    name: "Andor",
    key: 21
  },
  {
    name: "Ahsoka",
    key: 22
  },
  {
    name: "Young Jedi Adventures",
    key: 23
  }
]

export const species = [{"name":"ABEDNEDO","img":"https://i.imgur.com/fNlgaeb.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Abednedo","obscurity":3},
{"name":"ABERSYN SYMBIOTE","img":"https://i.imgur.com/SxS4YwT.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Abersyn_symbiote","obscurity":5},
{"name":"ABYSSIN","img":"https://i.imgur.com/xgwYqLC.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Abyssin","obscurity":4},
{"name":"ADARIAN","img":"https://i.imgur.com/nfMcTDA.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Adarian","obscurity":6},
{"name":"ADVOZSE","img":"https://i.imgur.com/WEujGQT.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Advozse","obscurity":4},
{"name":"AEOSIAN","img":"https://i.imgur.com/hao8X3Q.png","appearance":14,"link":"https://starwars.fandom.com/wiki/Aeosian","obscurity":4},
{"name":"AGARIAN","img":"https://i.imgur.com/IGYDuAO.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Agarian","obscurity":6},
{"name":"AILON","img":"https://i.imgur.com/nzrPulf.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Ailon","obscurity":6},
{"name":"AING-TII","img":"https://i.imgur.com/mlPJJRt.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Aing-Tii","obscurity":6},
{"name":"AKI-AKI","img":"https://i.imgur.com/jUehxwn.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Aki-Aki","obscurity":4},
{"name":"ALAMITE","img":"https://i.imgur.com/nhjl303.png","appearance":16,"link":"https://starwars.fandom.com/wiki/Alamite","obscurity":4},
{"name":"ALAPHANI","img":"https://i.imgur.com/CKH6eGE.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Alaphani","obscurity":6},
{"name":"ALAZMEC","img":"https://i.imgur.com/MW1aZTZ.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Alazmec","obscurity":4},
{"name":"ALCEDIAN","img":"https://i.imgur.com/Rtrx4Po.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Alcedian","obscurity":5},
{"name":"ALEENA","img":"https://i.imgur.com/7f9segU.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Aleena","obscurity":4},
{"name":"ALOXIAN","img":"https://i.imgur.com/tSRxmmZ.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Aloxian","obscurity":5},
{"name":"ALTIRI","img":"https://i.imgur.com/Rr9cj4m.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Altiri","obscurity":6},
{"name":"ALZARIAN","img":"https://i.imgur.com/G5Mg6NR.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Alzarian","obscurity":6},
{"name":"AMANI","img":"https://i.imgur.com/vy1qlmN.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Amani","obscurity":4},
{"name":"ANACONDAN","img":"https://i.imgur.com/1SLhTVP.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Anacondan","obscurity":4},
{"name":"ANOMID","img":"https://i.imgur.com/WCfiXAV.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Anomid","obscurity":4},
{"name":"ANSELMI","img":"https://i.imgur.com/DyU0lRD.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Anselmi","obscurity":5},
{"name":"ANX","img":"https://i.imgur.com/b7aSRKr.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Anx","obscurity":4},
{"name":"ANZAT","img":"https://i.imgur.com/DGZwBq3.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Anzat","obscurity":4},
{"name":"ANZELLAN","img":"https://i.imgur.com/BUo8eIx.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Anzellan","obscurity":4},
{"name":"AQUALISH","img":"https://i.imgur.com/VToQ0ax.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Aqualish","obscurity":3},
{"name":"ARCONA","img":"https://i.imgur.com/t8YScZq.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Arcona","obscurity":4},
{"name":"ARDENNIAN","img":"https://i.imgur.com/5juAj5v.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Ardennian","obscurity":3},
{"name":"ARKANIAN","img":"https://i.imgur.com/3D8PIks.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Arkanian","obscurity":5},
{"name":"ARKANIAN OFFSHOOT","img":"https://i.imgur.com/K5e5Sxo.png","appearance":15,"link":"https://starwars.fandom.com/wiki/Arkanian Offshoot","obscurity":4},
{"name":"ARTHURIAN","img":"https://i.imgur.com/Buobqqf.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Arthurian","obscurity":5},
{"name":"ARTIODAC","img":"https://i.imgur.com/I9BP077.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Artiodac","obscurity":4},
{"name":"ARUZAN","img":"https://i.imgur.com/heIwcmC.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Aruzan","obscurity":5},
{"name":"ASKAJIAN","img":"https://i.imgur.com/hsnjW7Y.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Askajian","obscurity":4},
{"name":"ASOGIAN","img":"https://i.imgur.com/m24zmPQ.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Asogian","obscurity":4},
{"name":"AYROU","img":"https://i.imgur.com/dHXQlC9.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Ayrou","obscurity":5},
{"name":"AZUMEL","img":"https://i.imgur.com/2xCxCB8.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Azumel","obscurity":4},
{"name":"BALNAB","img":"https://i.imgur.com/7CO6fFc.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Balnab","obscurity":4},
{"name":"BALOSAR","img":"https://i.imgur.com/13ADCWR.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Balosar","obscurity":4},
{"name":"BANCHIIAN","img":"https://i.imgur.com/L8Huwen.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Banchiian","obscurity":6},
{"name":"BARABEL","img":"https://i.imgur.com/x9UnDa9.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Barabel","obscurity":5},
{"name":"BARAGWIN","img":"https://i.imgur.com/Lz4fZv7.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Baragwin","obscurity":4},
{"name":"BARBADELAN","img":"https://i.imgur.com/NnpGkzL.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Barbadelan","obscurity":4},
{"name":"BARDOTTAN","img":"https://i.imgur.com/UuVopGG.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Bardottan","obscurity":4},
{"name":"BAROLIAN","img":"https://i.imgur.com/q4ZIchM.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Barolian","obscurity":6},
{"name":"BATTRACH","img":"https://i.imgur.com/SExtd3x.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Battrach","obscurity":6},
{"name":"BELUGAN","img":"https://i.imgur.com/skVJGCF.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Belugan","obscurity":4},
{"name":"BENATHY","img":"https://i.imgur.com/Lv6frG2.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Benathy","obscurity":6},
{"name":"BENDU","img":"https://i.imgur.com/5Rp1GoN.png","appearance":13,"link":"https://starwars.fandom.com/wiki/Bendu","obscurity":4},
{"name":"BESALISK","img":"https://i.imgur.com/ah4gQs8.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Besalisk","obscurity":4},
{"name":"BIMM","img":"https://i.imgur.com/NnyuDAH.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Bimm","obscurity":4},
{"name":"BIMM (FURRED)","img":"https://i.imgur.com/DEyUE1C.png","appearance":null,"link":"https://starwars.fandom.com/wiki/Bimm_(furred_species)","obscurity":4},
{"name":"BITH","img":"https://i.imgur.com/DzHW8m3.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Bith","obscurity":3},
{"name":"BIVALL","img":"https://i.imgur.com/M7isYp0.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Bivall","obscurity":4},
{"name":"BLARINA","img":"https://i.imgur.com/0ofGO9l.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Blarina","obscurity":4},
{"name":"BLUTOPIAN","img":"https://i.imgur.com/C7ms4iv.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Blutopian","obscurity":4},
{"name":"BODACH'I","img":"https://i.imgur.com/Qo9KAtU.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Bodachi","obscurity":6},
{"name":"BOLTRUNIAN","img":"https://i.imgur.com/v1SEv7P.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Boltrunian","obscurity":6},
{"name":"BONBRAK","img":"https://i.imgur.com/w7BBenG.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Bonbrak","obscurity":5},
{"name":"BOOSODIAN","img":"https://i.imgur.com/YtKMLJh.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Boosodian","obscurity":4},
{"name":"BOTHAN","img":"https://i.imgur.com/ax6CCd1.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Bothan","obscurity":4},
{"name":"BPFASSHI","img":"https://i.imgur.com/f5ZMh7Q.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Bpfasshi","obscurity":5},
{"name":"BRAVAISIAN","img":"https://i.imgur.com/SRfo0DF.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Bravaisian","obscurity":4},
{"name":"BRAYAN","img":"https://i.imgur.com/83LLDGM.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Brayan","obscurity":6},
{"name":"BRITARRO","img":"https://i.imgur.com/XY0ryXP.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Britarro","obscurity":4},
{"name":"BRIZZIT","img":"https://i.imgur.com/n9DjGyI.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Brizzit","obscurity":4},
{"name":"BUFOPEL","img":"https://i.imgur.com/daCPw0r.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Bufopel","obscurity":4},
{"name":"BURSANTIAN","img":"https://i.imgur.com/oJhHFZA.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Bursantian","obscurity":6},
{"name":"CAAMASI","img":"https://i.imgur.com/pfKeXsC.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Caamasi","obscurity":5},
{"name":"CANDOVANTAN","img":"https://i.imgur.com/aGlSyvW.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Candovantan","obscurity":4},
{"name":"CAPHEX","img":"https://i.imgur.com/znPdUYo.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Caphex","obscurity":4},
{"name":"CASKADAG","img":"https://i.imgur.com/LeopExv.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Caskadag","obscurity":4},
{"name":"CATHAR","img":"https://i.imgur.com/Jfqj0I0.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Cathar","obscurity":5},
{"name":"CELEGIAN","img":"https://i.imgur.com/KHPNu5p.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Celegian","obscurity":6},
{"name":"CEREAN","img":"https://i.imgur.com/wuvGyaP.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Cerean","obscurity":3},
{"name":"CHADRA-FAN","img":"https://i.imgur.com/d9kehBA.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Chadra-Fan","obscurity":4},
{"name":"CHAGRIAN","img":"https://i.imgur.com/pxVf5Ek.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Chagrian","obscurity":4},
{"name":"CHALHUDDAN","img":"https://i.imgur.com/UCugQ5w.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Chalhuddan","obscurity":5},
{"name":"CHELIDAE","img":"https://i.imgur.com/BOzpKBK.png","appearance":14,"link":"https://starwars.fandom.com/wiki/Chelidae","obscurity":4},
{"name":"CHEVIN","img":"https://i.imgur.com/eBLtdCJ.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Chevin","obscurity":4},
{"name":"CHISS","img":"https://i.imgur.com/HB4K2uK.png","appearance":13,"link":"https://starwars.fandom.com/wiki/Chiss","obscurity":3},
{"name":"CHORTOSE","img":"https://i.imgur.com/Lqqc5ay.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Chortose","obscurity":6},
{"name":"CHRISTOPHSIAN","img":"https://i.imgur.com/ziNZALS.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Christophsian","obscurity":4},
{"name":"CINGULON","img":"https://i.imgur.com/7jUtoVs.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Cingulon","obscurity":4},
{"name":"CLABRONIAN","img":"https://i.imgur.com/qpoAUTL.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Clabronian","obscurity":6},
{"name":"CLANTANNI","img":"https://i.imgur.com/7N9h6Ap.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Clantanni","obscurity":6},
{"name":"CLAWDITE","img":"https://i.imgur.com/DpPpgFR.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Clawdite","obscurity":3},
{"name":"CLODDOGRAN","img":"https://i.imgur.com/rZBK1Mw.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Cloddogran","obscurity":4},
{"name":"COLICOID","img":"https://i.imgur.com/7rCphWX.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Colicoid","obscurity":6},
{"name":"CONDLURAN","img":"https://i.imgur.com/HpNQq5s.png","appearance":20,"link":"https://starwars.fandom.com/wiki/Condluran","obscurity":3},
{"name":"CONJENI","img":"https://i.imgur.com/UvinZ5T.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Conjeni","obscurity":6},
{"name":"COSIAN","img":"https://i.imgur.com/MOu1DYM.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Cosian","obscurity":4},
{"name":"COYNITE","img":"https://i.imgur.com/olE8Q0A.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Coynite","obscurity":6},
{"name":"CRAGMOLOID","img":"https://i.imgur.com/CSFa8P3.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Cragmoloid","obscurity":6},
{"name":"CROCIN","img":"https://i.imgur.com/OaQQh01.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Crocin","obscurity":4},
{"name":"CROLUTE","img":"https://i.imgur.com/4nYzxrh.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Crolute","obscurity":4},
{"name":"CRULKON","img":"https://i.imgur.com/tIUpHf7.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Crulkon","obscurity":6},
{"name":"CULISETTO","img":"https://i.imgur.com/iF0OKUc.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Culisetto","obscurity":4},
{"name":"CYCLORRIAN","img":"https://i.imgur.com/0RRPDoh.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Cyclorrian","obscurity":4},
{"name":"CYPHARI","img":"https://i.imgur.com/2aGXIC7.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Cyphari","obscurity":5},
{"name":"CZERIALAN","img":"https://i.imgur.com/azcQ0CC.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Czerialan","obscurity":4},
{"name":"DA-VLUNNIAN","img":"https://i.imgur.com/jKhFpjG.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Da-Vlunnian","obscurity":6},
{"name":"DANZIKAN","img":"https://i.imgur.com/c6OkmCC.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Danzikan","obscurity":4},
{"name":"DARSHI","img":"https://i.imgur.com/iI5LfZO.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Darshi","obscurity":5},
{"name":"DASHADE","img":"https://i.imgur.com/adChdR9.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Dashade","obscurity":5},
{"name":"DEFEL","img":"https://i.imgur.com/3wzsxuF.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Defel","obscurity":4},
{"name":"DELPHIDIAN","img":"https://i.imgur.com/CyvXiVH.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Delphidian","obscurity":4},
{"name":"DEMIRIAN","img":"https://i.imgur.com/Ps4A76c.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Demirian","obscurity":6},
{"name":"DEVARONIAN","img":"https://i.imgur.com/ulrL5EV.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Devaronian","obscurity":3},
{"name":"DEVLIKK","img":"https://i.imgur.com/mdLAucK.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Devlikk","obscurity":4},
{"name":"DEYMASOLLIAN","img":"https://i.imgur.com/ikIK1vP.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Deymasollian","obscurity":4},
{"name":"DIATHIM","img":"https://i.imgur.com/4LpXKyh.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Diathim","obscurity":4},
{"name":"DIDYNON","img":"https://i.imgur.com/aPLDQkG.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Didynon","obscurity":4},
{"name":"DOR NAMETHIAN","img":"https://i.imgur.com/fEW3nfq.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Dor Namethian","obscurity":4},
{"name":"DORNEAN","img":"https://i.imgur.com/EwGSQzk.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Dornean","obscurity":6},
{"name":"DOWUTIN","img":"https://i.imgur.com/IpaHZK9.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Dowutin","obscurity":4},
{"name":"DRABATAN","img":"https://i.imgur.com/uYLJI4S.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Drabatan","obscurity":4},
{"name":"DRADAN","img":"https://i.imgur.com/KE7JYQR.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Dradan","obscurity":6},
{"name":"DRAEDAN","img":"https://i.imgur.com/3qoTraG.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Draedan","obscurity":5},
{"name":"DRAETHOS","img":"https://i.imgur.com/jXEIJmz.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Draethos","obscurity":5},
{"name":"DRALL","img":"https://i.imgur.com/Zny6HlV.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Drall","obscurity":6},
{"name":"DRENGIR","img":"https://i.imgur.com/lmWKbsv.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Drengir","obscurity":5},
{"name":"DRESSELLIAN","img":"https://i.imgur.com/BgiMbWr.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Dressellian","obscurity":4},
{"name":"DUG","img":"https://i.imgur.com/y7VMSNV.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Dug","obscurity":3},
{"name":"DUINUOGWUIN","img":"https://i.imgur.com/bQE9otE.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Duinuogwuin","obscurity":6},
{"name":"DULOK","img":"https://i.imgur.com/cJNZycM.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Dulok","obscurity":5},
{"name":"DUPEI","img":"https://i.imgur.com/WCEJAkT.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Dupei","obscurity":6},
{"name":"DUROS","img":"https://i.imgur.com/XGUWquT.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Duros","obscurity":3},
{"name":"DWUNI","img":"https://i.imgur.com/gP8kqr3.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Dwuni","obscurity":4},
{"name":"DYBRINTHE","img":"https://i.imgur.com/6RqHdgX.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Dybrinthe","obscurity":4},
{"name":"DYPLOTID","img":"https://i.imgur.com/Wty00Wi.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Dyplotid","obscurity":4},
{"name":"ECHANI","img":"https://i.imgur.com/sDf8tL8.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Echani","obscurity":6},
{"name":"ELNACON","img":"https://i.imgur.com/QkGB1zr.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Elnacon","obscurity":4},
{"name":"ELOM","img":"https://i.imgur.com/R3hkEdQ.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Elom","obscurity":4},
{"name":"ELOMIN","img":"https://i.imgur.com/cEvJUIy.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Elomin","obscurity":5},
{"name":"EM'LIY","img":"https://i.imgur.com/tQzSc1A.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Emliy","obscurity":6},
{"name":"ENSO","img":"https://i.imgur.com/1qrtHOb.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Enso","obscurity":4},
{"name":"ER’KIT","img":"https://i.imgur.com/EgapCmg.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Er’Kit","obscurity":4},
{"name":"ESOOMIAN","img":"https://i.imgur.com/YUixHxN.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Esoomian","obscurity":6},
{"name":"ESPIRION","img":"https://i.imgur.com/wC0FcwN.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Espirion","obscurity":5},
{"name":"EVERENI","img":"https://i.imgur.com/v4EQ3pg.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Evereni","obscurity":5},
{"name":"EWOK","img":"https://i.imgur.com/X24ulUk.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Ewok","obscurity":1},
{"name":"EZARAA","img":"https://i.imgur.com/4TJ9k8u.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Ezaraa","obscurity":5},
{"name":"FALLEEN","img":"https://i.imgur.com/xsCZSwQ.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Falleen","obscurity":4},
{"name":"FANTANINE","img":"https://i.imgur.com/WIXg9L8.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Fantanine","obscurity":6},
{"name":"FAUST","img":"https://i.imgur.com/eWjzByZ.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Faust","obscurity":4},
{"name":"FEEORIN","img":"https://i.imgur.com/8NG0KU7.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Feeorin","obscurity":5},
{"name":"FELUCIAN","img":"https://i.imgur.com/2TJx9BH.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Felucian","obscurity":4},
{"name":"FIAN","img":"https://i.imgur.com/TM9QzYX.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Fian","obscurity":6},
{"name":"FILAR-NITZAN","img":"https://i.imgur.com/cziuRJ3.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Filar-Nitzan","obscurity":6},
{"name":"FILLITHAR","img":"https://i.imgur.com/UXltCSw.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Fillithar","obscurity":5},
{"name":"FIRRERREO","img":"https://i.imgur.com/8Of7oLI.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Firrerreo","obscurity":6},
{"name":"FISHER","img":"https://i.imgur.com/2UpUNtz.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Fisher","obscurity":6},
{"name":"FIUMARIAN","img":"https://i.imgur.com/XfccQvd.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Fiumarian","obscurity":6},
{"name":"FLUGGRIAN","img":"https://i.imgur.com/tycyOt3.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Fluggrian","obscurity":4},
{"name":"FRENK","img":"https://i.imgur.com/msLYjSw.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Frenk","obscurity":4},
{"name":"FRIGOSIAN","img":"https://i.imgur.com/x8n53Eg.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Frigosian","obscurity":4},
{"name":"FROG-DOG","img":"https://i.imgur.com/Hkupg5T.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Frog-Dog","obscurity":4},
{"name":"FROZIAN","img":"https://i.imgur.com/u4NiGDV.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Frozian","obscurity":5},
{"name":"FYLARI","img":"https://i.imgur.com/2MXvqt2.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Fylari","obscurity":6},
{"name":"GABDORIN","img":"https://i.imgur.com/9HrcWcn.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Gabdorin","obscurity":4},
{"name":"GALDERIAN","img":"https://i.imgur.com/cAmbemm.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Galderian","obscurity":6},
{"name":"GALLUSIAN","img":"https://i.imgur.com/Hi3NyUf.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Gallusian","obscurity":4},
{"name":"GAMORREAN","img":"https://i.imgur.com/CRy2ONv.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Gamorrean","obscurity":2},
{"name":"GAND","img":"https://i.imgur.com/LcQGXfB.png","appearance":5,"link":"https://starwars.fandom.com/wiki/Gand","obscurity":4},
{"name":"GANK","img":"https://i.imgur.com/NgOwtY3.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Gank","obscurity":5},
{"name":"GEN'DAI","img":"https://i.imgur.com/lTmlcpz.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Gendai","obscurity":5},
{"name":"GEONOSIAN","img":"https://i.imgur.com/stfN4dK.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Geonosian","obscurity":3},
{"name":"GERANITE","img":"https://i.imgur.com/8XVrKbW.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Geranite","obscurity":4},
{"name":"GHULAR","img":"https://i.imgur.com/Rt0sAci.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Ghular","obscurity":6},
{"name":"GIGORAN","img":"https://i.imgur.com/nkxJF0f.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Gigoran","obscurity":4},
{"name":"GILLIAND","img":"https://i.imgur.com/X0UOEjh.png","appearance":14,"link":"https://starwars.fandom.com/wiki/Gilliand","obscurity":4},
{"name":"GINMID","img":"https://i.imgur.com/T4qp41R.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Ginmid","obscurity":4},
{"name":"GIVIN","img":"https://i.imgur.com/xxJ9aeR.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Givin","obscurity":4},
{"name":"GLOOVAN","img":"https://i.imgur.com/pA4AcGo.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Gloovan","obscurity":5},
{"name":"GLYMPHID","img":"https://i.imgur.com/yPwsmE0.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Glymphid","obscurity":4},
{"name":"GODOAN","img":"https://i.imgur.com/cKNMH2o.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Godoan","obscurity":6},
{"name":"GORAX","img":"https://i.imgur.com/VcMAC5G.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Gorax","obscurity":5},
{"name":"GORMAK","img":"https://i.imgur.com/qNIPtFy.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Gormak","obscurity":6},
{"name":"GORPH","img":"https://i.imgur.com/4Yp2wxz.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Gorph","obscurity":6},
{"name":"GOSSAM","img":"https://i.imgur.com/uiB9GMc.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Gossam","obscurity":4},
{"name":"GOTAL","img":"https://i.imgur.com/HRnmPF0.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Gotal","obscurity":3},
{"name":"GOTARITE","img":"https://i.imgur.com/ihLO92N.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Gotarite","obscurity":4},
{"name":"GOZZO","img":"https://i.imgur.com/TXMHfDY.png","appearance":14,"link":"https://starwars.fandom.com/wiki/Gozzo","obscurity":4},
{"name":"GRAN","img":"https://i.imgur.com/dyUWSJl.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Gran","obscurity":4},
{"name":"GREE","img":"https://i.imgur.com/NhiUk6m.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Gree","obscurity":6},
{"name":"GRINDALID","img":"https://i.imgur.com/Y7hFVZg.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Grindalid","obscurity":4},
{"name":"GRYSK","img":"https://i.imgur.com/UyXit1x.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Grysk","obscurity":5},
{"name":"GUNGAN (ANKURA)","img":"https://i.imgur.com/O9pj6pD.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Gungan (Ankura)","obscurity":3},
{"name":"GUNGAN (OTOLLA)","img":"https://i.imgur.com/F5ijcdI.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Gungan (Otolla)","obscurity":2},
{"name":"H'NEMTHE","img":"https://i.imgur.com/NYhHWLJ.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Hnemthe","obscurity":4},
{"name":"HALAISI","img":"https://i.imgur.com/uSXFbak.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Halaisi","obscurity":4},
{"name":"HARCH","img":"https://i.imgur.com/PSy7Kwt.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Harch","obscurity":4},
{"name":"HASSARIAN","img":"https://i.imgur.com/owWd0fb.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Hassarian","obscurity":6},
{"name":"HASSK","img":"https://i.imgur.com/5vbIWAB.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Hassk","obscurity":4},
{"name":"HEPTOOINIAN","img":"https://i.imgur.com/zL7RxtW.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Heptooinian","obscurity":4},
{"name":"HERGLIC","img":"https://i.imgur.com/ySxpZUJ.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Herglic","obscurity":5},
{"name":"HIITIAN","img":"https://i.imgur.com/VEAEpZE.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Hiitian","obscurity":5},
{"name":"HOLWUFF","img":"https://i.imgur.com/HzAuhxv.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Holwuff","obscurity":4},
{"name":"HOOJIB","img":"https://i.imgur.com/Ak6cSjz.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Hoojib","obscurity":6},
{"name":"HOOPALOO","img":"https://i.imgur.com/cnVSmha.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Hoopaloo","obscurity":5},
{"name":"HOOVER","img":"https://i.imgur.com/WtuhX6L.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Hoover","obscurity":4},
{"name":"HORIAN","img":"https://i.imgur.com/EIt9azs.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Horian","obscurity":6},
{"name":"HOUK","img":"https://i.imgur.com/xM8o3uI.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Houk","obscurity":5},
{"name":"HUMAN","img":"https://i.imgur.com/W1vihqh.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Human","obscurity":1},
{"name":"HUTT","img":"https://i.imgur.com/1yVZiRt.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Hutt","obscurity":1},
{"name":"HYLOBON","img":"https://i.imgur.com/VrVm4Yu.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Hylobon","obscurity":4},
{"name":"IAKARU","img":"https://i.imgur.com/VHBs5aH.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Iakaru","obscurity":4},
{"name":"IKKRIKKIAN","img":"https://i.imgur.com/BmYRCV3.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Ikkrikkian","obscurity":5},
{"name":"IKTOTCHI","img":"https://i.imgur.com/X0iMJNr.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Iktotchi","obscurity":3},
{"name":"IMROOSIAN","img":"https://i.imgur.com/hC2gHUW.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Imroosian","obscurity":4},
{"name":"IMZIG","img":"https://i.imgur.com/0TAxKqR.png","appearance":5,"link":"https://starwars.fandom.com/wiki/Imzig","obscurity":4},
{"name":"INLESHAT","img":"https://i.imgur.com/eQVohBq.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Inleshat","obscurity":6},
{"name":"IOTRAN","img":"https://i.imgur.com/7e3nIoY.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Iotran","obscurity":6},
{"name":"ISHI TIB","img":"https://i.imgur.com/V04Kxqt.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Ishi Tib","obscurity":4},
{"name":"ITHORIAN","img":"https://i.imgur.com/xknFwF9.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Ithorian","obscurity":3},
{"name":"JABLOGIAN","img":"https://i.imgur.com/2l7SUmE.png","appearance":13,"link":"https://starwars.fandom.com/wiki/Jablogian","obscurity":4},
{"name":"JALUKU","img":"https://i.imgur.com/25TbaMi.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Jaluku","obscurity":4},
{"name":"JAWA","img":"https://i.imgur.com/IOnnWrx.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Jawa","obscurity":1},
{"name":"JENET","img":"https://i.imgur.com/wWCBRo1.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Jenet","obscurity":6},
{"name":"JILRUAN","img":"https://i.imgur.com/asd8A0v.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Jilruan","obscurity":6},
{"name":"JINDA","img":"https://i.imgur.com/ZX6f1LQ.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Jinda","obscurity":6},
{"name":"KADRILLIAN","img":"https://i.imgur.com/5bUVpxY.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Kadrillian","obscurity":6},
{"name":"KAGE","img":"https://i.imgur.com/ccHBsAP.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Kage","obscurity":4},
{"name":"KAKALA","img":"https://i.imgur.com/OQ2GOLT.png","appearance":21,"link":"https://starwars.fandom.com/wiki/Kakala","obscurity":4},
{"name":"KALEESH","img":"https://i.imgur.com/jm82ov0.png","appearance":3,"link":"https://starwars.fandom.com/wiki/Kaleesh","obscurity":3},
{"name":"KALLERAN","img":"https://i.imgur.com/irJ7Nsj.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Kalleran","obscurity":5},
{"name":"KALLIDAHIN","img":"https://i.imgur.com/Yrvw2es.png","appearance":3,"link":"https://starwars.fandom.com/wiki/Kallidahin","obscurity":4},
{"name":"KAMINOAN","img":"https://i.imgur.com/F13S5gv.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Kaminoan","obscurity":2},
{"name":"KARKARODON","img":"https://i.imgur.com/w7HuBvx.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Karkarodon","obscurity":4},
{"name":"KASSK","img":"https://i.imgur.com/uT6x1Xu.png","appearance":14,"link":"https://starwars.fandom.com/wiki/Kassk","obscurity":4},
{"name":"KATIKOOT","img":"https://i.imgur.com/uvvzhpH.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Katikoot","obscurity":6},
{"name":"KEL DOR","img":"https://i.imgur.com/KQ987px.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Kel Dor","obscurity":3},
{"name":"KEREDIAN","img":"https://i.imgur.com/KR1Rb7m.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Keredian","obscurity":4},
{"name":"KERESTIAN","img":"https://i.imgur.com/DQYvpiO.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Kerestian","obscurity":4},
{"name":"KERKOIDEN","img":"https://i.imgur.com/Lwj4QxM.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Kerkoiden","obscurity":4},
{"name":"KERRAKK","img":"https://i.imgur.com/OMvdlG9.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Kerrakk","obscurity":6},
{"name":"KESHIAN","img":"https://i.imgur.com/h0RgYFf.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Keshian","obscurity":6},
{"name":"KESHIRI","img":"https://i.imgur.com/lX2yHHB.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Keshiri","obscurity":6},
{"name":"KESSURIAN","img":"https://i.imgur.com/T0XRxdJ.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Kessurian","obscurity":4},
{"name":"KETEERIAN","img":"https://i.imgur.com/31KHzmV.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Keteerian","obscurity":4},
{"name":"KETTON","img":"https://i.imgur.com/RGEiiAo.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Ketton","obscurity":6},
{"name":"KHOMMITE","img":"https://i.imgur.com/sjo4qvl.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Khommite","obscurity":6},
{"name":"KHORMAI","img":"https://i.imgur.com/XN069aD.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Khormai","obscurity":6},
{"name":"KHRAMBOAN","img":"https://i.imgur.com/SeNtkWy.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Khramboan","obscurity":4},
{"name":"KIFFAR","img":"https://i.imgur.com/Gf3VGMo.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Kiffar","obscurity":4},
{"name":"KINDALO","img":"https://i.imgur.com/MIi3u1M.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Kindalo","obscurity":4},
{"name":"KITONAK","img":"https://i.imgur.com/QU8AUAy.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Kitonak","obscurity":4},
{"name":"KLATOOINIAN","img":"https://i.imgur.com/7ZSyhm1.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Klatooinian","obscurity":4},
{"name":"KOBOK","img":"https://i.imgur.com/Xn2zNhC.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Kobok","obscurity":6},
{"name":"KOORIVAR","img":"https://i.imgur.com/q6hTASO.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Koorivar","obscurity":4},
{"name":"KOTABI","img":"https://i.imgur.com/LUmhMYP.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Kotabi","obscurity":5},
{"name":"KOWAKIAN","img":"https://i.imgur.com/jXZzg0U.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Kowakian","obscurity":3},
{"name":"KRAWG","img":"https://i.imgur.com/mTiU9U3.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Krawg","obscurity":6},
{"name":"KRISH","img":"https://i.imgur.com/vNM0dHr.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Krish","obscurity":6},
{"name":"KUBAZ","img":"https://i.imgur.com/9ep11Fd.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Kubaz","obscurity":3},
{"name":"KUDON","img":"https://i.imgur.com/egTIGOZ.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Kudon","obscurity":5},
{"name":"KUMUMGAH","img":"https://i.imgur.com/uLZHgi4.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Kumumgah","obscurity":6},
{"name":"KYUZO","img":"https://i.imgur.com/5wlnAqy.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Kyuzo","obscurity":4},
{"name":"LACERTILO","img":"https://i.imgur.com/3FjzrrT.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Lacertilo","obscurity":4},
{"name":"LAMPROID","img":"https://i.imgur.com/QgKqcxX.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Lamproid","obscurity":4},
{"name":"LANAI","img":"https://i.imgur.com/24Wm9xF.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Lanai","obscurity":4},
{"name":"LANNIK","img":"https://i.imgur.com/t6AWUih.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Lannik","obscurity":4},
{"name":"LASAT","img":"https://i.imgur.com/aX5mBfn.png","appearance":13,"link":"https://starwars.fandom.com/wiki/Lasat","obscurity":3},
{"name":"LATERO","img":"https://i.imgur.com/SigRWAX.png","appearance":15,"link":"https://starwars.fandom.com/wiki/Latero","obscurity":3},
{"name":"LEFFINGITE","img":"https://i.imgur.com/DYSSY8g.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Leffingite","obscurity":4},
{"name":"LEPI","img":"https://i.imgur.com/qafKOJF.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Lepi","obscurity":5},
{"name":"LEYAKIAN","img":"https://i.imgur.com/uuZBOlJ.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Leyakian","obscurity":4},
{"name":"LIVORNO","img":"https://i.imgur.com/XlMo7yM.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Livorno","obscurity":6},
{"name":"LONTO","img":"https://i.imgur.com/MBw1TFd.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Lonto","obscurity":5},
{"name":"LORRDIAN","img":"https://i.imgur.com/ulA7cT5.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Lorrdian","obscurity":4},
{"name":"LUCEM","img":"https://i.imgur.com/nhHxJtX.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Lucem","obscurity":6},
{"name":"LUPR'OR","img":"https://i.imgur.com/Fp3R0Ch.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Lupror","obscurity":6},
{"name":"LURMEN","img":"https://i.imgur.com/hDhTrPn.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Lurmen","obscurity":3},
{"name":"LUTRILLIAN","img":"https://i.imgur.com/7CQ9INH.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Lutrillian","obscurity":4},
{"name":"LYNNA","img":"https://i.imgur.com/gWISvvJ.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Lynna","obscurity":6},
{"name":"MAIRAN","img":"https://i.imgur.com/5IH7GFn.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Mairan","obscurity":4},
{"name":"MANTELLIAN SAVRIP","img":"https://i.imgur.com/gHiDNjv.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Mantellian Savrip","obscurity":4},
{"name":"MARTIGRADE","img":"https://i.imgur.com/8AtwOsb.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Martigrade","obscurity":5},
{"name":"MASSASSI","img":"https://i.imgur.com/6RtkUlq.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Massassi","obscurity":5},
{"name":"MAZ KANATA'S SPECIES","img":"https://i.imgur.com/FmlLEn3.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Maz Kanatas Species","obscurity":1},
{"name":"MEERIAN","img":"https://i.imgur.com/bRfstAM.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Meerian","obscurity":6},
{"name":"MEFTIAN","img":"https://i.imgur.com/H5v6qZD.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Meftian","obscurity":4},
{"name":"MELBU","img":"https://i.imgur.com/iwgXcBu.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Melbu","obscurity":4},
{"name":"MELITTO","img":"https://i.imgur.com/HPZN8ga.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Melitto","obscurity":4},
{"name":"MIKKIAN","img":"https://i.imgur.com/rf3nCUm.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Mikkian","obscurity":4},
{"name":"MIMBANESE","img":"https://i.imgur.com/zSWx8GO.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Mimbanese","obscurity":4},
{"name":"MIRALUKA","img":"https://i.imgur.com/TAh6C8w.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Miraluka","obscurity":5},
{"name":"MIRIALAN","img":"https://i.imgur.com/Xyf85RY.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Mirialan","obscurity":3},
{"name":"MOGGONITE","img":"https://i.imgur.com/haaSQNp.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Moggonite","obscurity":6},
{"name":"MOHSENIAN","img":"https://i.imgur.com/96J4ody.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Mohsenian","obscurity":4},
{"name":"MOLDWARP","img":"https://i.imgur.com/AT4G8L2.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Moldwarp","obscurity":4},
{"name":"MON CALAMARI","img":"https://i.imgur.com/pUVmADS.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Mon Calamari","obscurity":2},
{"name":"MONNOK","img":"https://i.imgur.com/8sOW9xx.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Monnok","obscurity":4},
{"name":"MOOGAN","img":"https://i.imgur.com/OPzG4YM.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Moogan","obscurity":4},
{"name":"MORAGAN","img":"https://i.imgur.com/c7Clpyy.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Moragan","obscurity":6},
{"name":"MORSEERIAN","img":"https://i.imgur.com/pRFXTco.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Morseerian","obscurity":4},
{"name":"MOURNISH","img":"https://i.imgur.com/JIq5fTL.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Mournish","obscurity":5},
{"name":"MOYN","img":"https://i.imgur.com/BbfLEMH.png","appearance":13,"link":"https://starwars.fandom.com/wiki/Moyn","obscurity":4},
{"name":"MRISS","img":"https://i.imgur.com/s0JUOFl.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Mriss","obscurity":6},
{"name":"MUSTAFARIAN","img":"https://i.imgur.com/WOnj2vu.png","appearance":3,"link":"https://starwars.fandom.com/wiki/Mustafarian","obscurity":4},
{"name":"MUUN","img":"https://i.imgur.com/Yeef0u0.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Muun","obscurity":4},
{"name":"MYTHROL","img":"https://i.imgur.com/nA1pd6H.png","appearance":16,"link":"https://starwars.fandom.com/wiki/Mythrol","obscurity":3},
{"name":"NALRONI","img":"https://i.imgur.com/NXuAEnJ.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Nalroni","obscurity":6},
{"name":"NAMARA","img":"https://i.imgur.com/Lz5ZakK.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Namara","obscurity":4},
{"name":"NARQUOIS","img":"https://i.imgur.com/B8NI7t7.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Narquois","obscurity":4},
{"name":"NAUTOLAN","img":"https://i.imgur.com/Uo3osKo.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Nautolan","obscurity":3},
{"name":"NAZZAR","img":"https://i.imgur.com/vfPn8eF.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Nazzar","obscurity":6},
{"name":"NEIMOIDIAN","img":"https://i.imgur.com/GlBVo95.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Neimoidian","obscurity":3},
{"name":"NELVAANIAN","img":"https://i.imgur.com/2ltqofm.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Nelvaanian","obscurity":6},
{"name":"NEPHRAN","img":"https://i.imgur.com/bNHedWX.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Nephran","obscurity":4},
{"name":"NIKTO","img":"https://i.imgur.com/wtcvwZI.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Nikto","obscurity":3},
{"name":"NIMBANEL","img":"https://i.imgur.com/mr5dCeK.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Nimbanel","obscurity":4},
{"name":"NITHORN","img":"https://i.imgur.com/WNrNb3z.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Nithorn","obscurity":4},
{"name":"NOBILLIAN","img":"https://i.imgur.com/Q283Agn.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Nobillian","obscurity":4},
{"name":"NOGHRI","img":"https://i.imgur.com/BJ5FFUt.png","appearance":13,"link":"https://starwars.fandom.com/wiki/Noghri","obscurity":3},
{"name":"NOSAURIAN","img":"https://i.imgur.com/eW0ADOQ.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Nosaurian","obscurity":4},
{"name":"NOTHOIIN","img":"https://i.imgur.com/urr8DrL.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Nothoiin","obscurity":6},
{"name":"NOTI","img":"https://i.imgur.com/kB0DyIL.png","appearance":22,"link":"https://starwars.fandom.com/wiki/Noti","obscurity":4},
{"name":"NU-COSIAN","img":"https://i.imgur.com/eADGvPq.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Nu-Cosian","obscurity":4},
{"name":"NUKNOG","img":"https://i.imgur.com/yCPhDyS.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Nuknog","obscurity":4},
{"name":"NUXAN","img":"https://i.imgur.com/sOc083I.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Nuxan","obscurity":6},
{"name":"OCTANI","img":"https://i.imgur.com/GgFsqcD.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Octani","obscurity":6},
{"name":"OCTEROID","img":"https://i.imgur.com/vFz1PUZ.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Octeroid","obscurity":4},
{"name":"OGEMITE","img":"https://i.imgur.com/nCmYHLK.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Ogemite","obscurity":6},
{"name":"OMWATI","img":"https://i.imgur.com/UNEUSFY.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Omwati","obscurity":6},
{"name":"ONGIDAE","img":"https://i.imgur.com/f5Xv0JX.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Ongidae","obscurity":4},
{"name":"ONGREE","img":"https://i.imgur.com/BPz47ig.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Ongree","obscurity":4},
{"name":"ONODONE","img":"https://i.imgur.com/6kLvqYv.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Onodone","obscurity":4},
{"name":"OOTOO","img":"https://i.imgur.com/C1y2zHA.png","appearance":23,"link":"https://starwars.fandom.com/wiki/Ootoo","obscurity":4},
{"name":"OROOTUROOIAN","img":"https://i.imgur.com/3iPq7vA.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Orooturooian","obscurity":6},
{"name":"ORTOLAN","img":"https://i.imgur.com/jjThIzk.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Ortolan","obscurity":4},
{"name":"OTTEGAN","img":"https://i.imgur.com/aCDIyGe.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Ottegan","obscurity":4},
{"name":"OVISSIAN","img":"https://i.imgur.com/KVUw9uX.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Ovissian","obscurity":4},
{"name":"OZRELANSO","img":"https://i.imgur.com/sSvCerJ.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Ozrelanso","obscurity":4},
{"name":"PA'LOWICK","img":"https://i.imgur.com/WWyfHGt.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Palowick","obscurity":4},
{"name":"PACITHHIP","img":"https://i.imgur.com/cWqfsEY.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Pacithhip","obscurity":4},
{"name":"PALANDAG","img":"https://i.imgur.com/DERw6CM.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Palandag","obscurity":4},
{"name":"PALLIDUVAN","img":"https://i.imgur.com/hKkdIJy.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Palliduvan","obscurity":3},
{"name":"PANTORAN","img":"https://i.imgur.com/dnK2m1z.png","appearance":3,"link":"https://starwars.fandom.com/wiki/Pantoran","obscurity":3},
{"name":"PARWAN","img":"https://i.imgur.com/n89Sn3b.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Parwan","obscurity":4},
{"name":"PASTORIAN","img":"https://i.imgur.com/lSzXK41.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Pastorian","obscurity":6},
{"name":"PATITITE","img":"https://i.imgur.com/r7Xq7HH.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Patitite","obscurity":4},
{"name":"PATROLIAN","img":"https://i.imgur.com/DbxJkuI.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Patrolian","obscurity":4},
{"name":"PAU’AN","img":"https://i.imgur.com/r8DhAiY.png","appearance":3,"link":"https://starwars.fandom.com/wiki/Pau’An","obscurity":4},
{"name":"PEASLE","img":"https://i.imgur.com/gCff79G.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Peasle","obscurity":5},
{"name":"PETRUSIAN","img":"https://i.imgur.com/unVc3FD.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Petrusian","obscurity":5},
{"name":"PHINDIAN","img":"https://i.imgur.com/f8IYAoo.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Phindian","obscurity":4},
{"name":"PHYDOLON","img":"https://i.imgur.com/FUjGzca.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Phydolon","obscurity":6},
{"name":"PIKPIK","img":"https://i.imgur.com/tpmUKfw.png","appearance":23,"link":"https://starwars.fandom.com/wiki/Pikpik","obscurity":4},
{"name":"PKORIAN","img":"https://i.imgur.com/NSgs4hQ.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Pkorian","obscurity":5},
{"name":"POLETEC","img":"https://i.imgur.com/kAIhrYI.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Poletec","obscurity":4},
{"name":"POOBA","img":"https://i.imgur.com/rGzgnKy.png","appearance":23,"link":"https://starwars.fandom.com/wiki/Pooba","obscurity":3},
{"name":"PYKE","img":"https://i.imgur.com/bROCv0U.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Pyke","obscurity":3},
{"name":"Q'LTANE","img":"https://i.imgur.com/bNqv5oo.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Qltane","obscurity":6},
{"name":"QHULOSKIAN","img":"https://i.imgur.com/oJ1hLnt.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Qhuloskian","obscurity":5},
{"name":"QIRAASH","img":"https://i.imgur.com/NDHUSK3.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Qiraash","obscurity":4},
{"name":"QUARREN","img":"https://i.imgur.com/QmZ5sCR.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Quarren","obscurity":3},
{"name":"QUBEY","img":"https://i.imgur.com/vHQnmYN.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Qubey","obscurity":6},
{"name":"QUERMIAN","img":"https://i.imgur.com/R99jKrv.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Quermian","obscurity":4},
{"name":"QUOR'SAV","img":"https://i.imgur.com/KhUs07z.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Quorsav","obscurity":4},
{"name":"R'ORA","img":"https://i.imgur.com/gmRGMTM.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Rora","obscurity":6},
{"name":"RAKATAN","img":"https://i.imgur.com/RCdWNjK.png","appearance":21,"link":"https://starwars.fandom.com/wiki/Rakatan","obscurity":5},
{"name":"RANAT","img":"https://i.imgur.com/AdaCZwn.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Ranat","obscurity":4},
{"name":"RATTATAKI","img":"https://i.imgur.com/smQDf8y.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Rattataki","obscurity":6},
{"name":"RAWANIKOLA","img":"https://i.imgur.com/1HDQrfj.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Rawanikola","obscurity":4},
{"name":"REESARIAN","img":"https://i.imgur.com/uaKlJ1w.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Reesarian","obscurity":4},
{"name":"REKK","img":"https://i.imgur.com/q99JqLC.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Rekk","obscurity":6},
{"name":"REVWIEN","img":"https://i.imgur.com/h1h7Zt1.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Revwien","obscurity":6},
{"name":"RIORIAN","img":"https://i.imgur.com/rVsaKcr.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Riorian","obscurity":4},
{"name":"RISHII","img":"https://i.imgur.com/PNxj9qO.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Rishii","obscurity":6},
{"name":"RODIAN","img":"https://i.imgur.com/Xckkajq.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Rodian","obscurity":2},
{"name":"ROONAN","img":"https://i.imgur.com/WRBMPNL.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Roonan","obscurity":4},
{"name":"RUURIAN","img":"https://i.imgur.com/46DLLxe.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Ruurian","obscurity":6},
{"name":"RYBET","img":"https://i.imgur.com/GPN8pW3.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Rybet","obscurity":5},
{"name":"RYN","img":"https://i.imgur.com/e3UAOqR.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Ryn","obscurity":6},
{"name":"SABAT","img":"https://i.imgur.com/juTsxBc.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Sabat","obscurity":4},
{"name":"SAKAVIAN","img":"https://i.imgur.com/0IIv2n0.png","appearance":15,"link":"https://starwars.fandom.com/wiki/Sakavian","obscurity":5},
{"name":"SAKIYAN","img":"https://i.imgur.com/PAeEzOK.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Sakiyan","obscurity":4},
{"name":"SALENGA","img":"https://i.imgur.com/hKKc5sk.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Salenga","obscurity":4},
{"name":"SANYASSAN","img":"https://i.imgur.com/K8AzK2A.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Sanyassan","obscurity":6},
{"name":"SARKAN","img":"https://i.imgur.com/q33LfLO.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Sarkan","obscurity":4},
{"name":"SARKHAI","img":"https://i.imgur.com/tHA1lav.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Sarkhai","obscurity":6},
{"name":"SARRAVI","img":"https://i.imgur.com/1IiZxf5.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Sarravi","obscurity":6},
{"name":"SATHARI","img":"https://i.imgur.com/1rmKnap.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Sathari","obscurity":6},
{"name":"SAURIN","img":"https://i.imgur.com/nBuwGZB.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Saurin","obscurity":4},
{"name":"SCALVI","img":"https://i.imgur.com/wNNGtjL.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Scalvi","obscurity":6},
{"name":"SEGREDO","img":"https://i.imgur.com/61pTbwA.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Segredo","obscurity":5},
{"name":"SELKATH","img":"https://i.imgur.com/V1S79QL.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Selkath","obscurity":4},
{"name":"SELONIAN","img":"https://i.imgur.com/6P00IXT.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Selonian","obscurity":5},
{"name":"SEPHI","img":"https://i.imgur.com/FxxkKvT.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Sephi","obscurity":5},
{"name":"SHADDA-BI-BORAN","img":"https://i.imgur.com/eFYM3yi.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Shadda-Bi-Boran","obscurity":4},
{"name":"SHAHKIRIN","img":"https://i.imgur.com/jCGaLLT.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Shahkirin","obscurity":4},
{"name":"SHANI","img":"https://i.imgur.com/omUMEXA.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Shani","obscurity":5},
{"name":"SHAWDA UBB","img":"https://i.imgur.com/H5wK8oY.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Shawda Ubb","obscurity":4},
{"name":"SHISTAVANEN","img":"https://i.imgur.com/crgblrM.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Shistavanen","obscurity":4},
{"name":"SHOZER","img":"https://i.imgur.com/5AHjogP.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Shozer","obscurity":4},
{"name":"SHUNGBEEK","img":"https://i.imgur.com/En8mXni.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Shungbeek","obscurity":4},
{"name":"SHYDOPP","img":"https://i.imgur.com/2JfD8qa.png","appearance":16,"link":"https://starwars.fandom.com/wiki/Shydopp","obscurity":4},
{"name":"SIC-SIX","img":"https://i.imgur.com/fEzgk5k.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Sic-Six","obscurity":4},
{"name":"SIKURDIAN","img":"https://i.imgur.com/UFQpP55.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Sikurdian","obscurity":6},
{"name":"SINITEEN","img":"https://i.imgur.com/1FQYhdM.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Siniteen","obscurity":4},
{"name":"SITH","img":"https://i.imgur.com/jxM1qLA.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Sith","obscurity":6},
{"name":"SKAKOAN","img":"https://i.imgur.com/AkWJAom.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Skakoan","obscurity":4},
{"name":"SKRILLING","img":"https://i.imgur.com/2Yn3Pix.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Skrilling","obscurity":4},
{"name":"SNEEVEL","img":"https://i.imgur.com/kegPFDX.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Sneevel","obscurity":4},
{"name":"SNIVVIAN","img":"https://i.imgur.com/XiWKaaw.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Snivvian","obscurity":4},
{"name":"SOIKAN","img":"https://i.imgur.com/tArn5Ly.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Soikan","obscurity":5},
{"name":"SOLLUX","img":"https://i.imgur.com/k1ioYCT.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Sollux","obscurity":4},
{"name":"SQUAMATAN","img":"https://i.imgur.com/Sljdc0H.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Squamatan","obscurity":5},
{"name":"SQUIB","img":"https://i.imgur.com/MTsoxoY.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Squib","obscurity":6},
{"name":"SSORI","img":"https://i.imgur.com/NSpLU0z.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Ssori","obscurity":4},
{"name":"STENNES SHIFTER","img":"https://i.imgur.com/ZJLat6p.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Stennes Shifter","obscurity":4},
{"name":"SUERTON","img":"https://i.imgur.com/PWw0hO7.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Suerton","obscurity":4},
{"name":"SUGI","img":"https://i.imgur.com/WdWqa9a.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Sugi","obscurity":5},
{"name":"SULLUSTAN","img":"https://i.imgur.com/1umtvew.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Sullustan","obscurity":2},
{"name":"SWOKES SWOKES","img":"https://i.imgur.com/HupG5IY.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Swokes Swokes","obscurity":4},
{"name":"SY MYTHRIAN","img":"https://i.imgur.com/YDWKflJ.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Sy Mythrian","obscurity":4},
{"name":"SYMEONG","img":"https://i.imgur.com/uslClDt.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Symeong","obscurity":4},
{"name":"TAH'NUHNA","img":"https://i.imgur.com/zGHpSMM.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Tahnuhna","obscurity":6},
{"name":"TALID","img":"https://i.imgur.com/kjGkWF6.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Talid","obscurity":6},
{"name":"TALORTAI","img":"https://i.imgur.com/zvpl9or.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Talortai","obscurity":6},
{"name":"TALPIDDIAN","img":"https://i.imgur.com/OeufiOy.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Talpiddian","obscurity":4},
{"name":"TALPINI","img":"https://i.imgur.com/WxRoKCR.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Talpini","obscurity":4},
{"name":"TALZ","img":"https://i.imgur.com/HYerCPv.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Talz","obscurity":3},
{"name":"TARINNA","img":"https://i.imgur.com/CO2oby5.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Tarinna","obscurity":6},
{"name":"TARLAFAR","img":"https://i.imgur.com/gxjPgD4.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Tarlafar","obscurity":4},
{"name":"TARNAB","img":"https://i.imgur.com/cxvOO7V.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Tarnab","obscurity":4},
{"name":"TARSUNT","img":"https://i.imgur.com/apkDrw7.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Tarsunt","obscurity":4},
{"name":"TAUNG","img":"https://i.imgur.com/6wNCJBH.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Taung","obscurity":6},
{"name":"TAURILL","img":"https://i.imgur.com/6P0xhd3.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Taurill","obscurity":6},
{"name":"TAYMAR","img":"https://i.imgur.com/foD8WVL.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Taymar","obscurity":5},
{"name":"TECTOZIN","img":"https://i.imgur.com/gyWuBV1.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Tectozin","obscurity":6},
{"name":"TEEDO","img":"https://i.imgur.com/AgDrYSR.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Teedo","obscurity":4},
{"name":"TEEK","img":"https://i.imgur.com/L1Z1bJ1.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Teek","obscurity":6},
{"name":"TERRELIAN JANGO JUMPER","img":"https://i.imgur.com/TfgUbZR.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Terrelian Jango Jumper","obscurity":4},
{"name":"TEVELLAN","img":"https://i.imgur.com/UxGQp85.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Tevellan","obscurity":6},
{"name":"THARNAKAN","img":"https://i.imgur.com/JlUyg0E.png","appearance":23,"link":"https://starwars.fandom.com/wiki/Tharnakan","obscurity":4},
{"name":"THEELIN","img":"https://i.imgur.com/BBapV3E.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Theelin","obscurity":4},
{"name":"THISSPIASIAN","img":"https://i.imgur.com/3gmKRJD.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Thisspiasian","obscurity":4},
{"name":"THOLOTHIAN","img":"https://i.imgur.com/Dpb5zus.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Tholothian","obscurity":3},
{"name":"THRADIAN","img":"https://i.imgur.com/OhtQer7.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Thradian","obscurity":5},
{"name":"TISS'SHAR","img":"https://i.imgur.com/P2tPw00.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Tissshar","obscurity":6},
{"name":"TOGNATH","img":"https://i.imgur.com/qzKmtcN.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Tognath","obscurity":4},
{"name":"TOGORIAN","img":"https://i.imgur.com/DfUDllt.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Togorian","obscurity":5},
{"name":"TOGRUTA","img":"https://i.imgur.com/7vLhXyf.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Togruta","obscurity":2},
{"name":"TOONG","img":"https://i.imgur.com/Hix8OlM.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Toong","obscurity":4},
{"name":"TOSTONIAN","img":"https://i.imgur.com/JUI5KQd.png","appearance":20,"link":"https://starwars.fandom.com/wiki/Tostonian","obscurity":4},
{"name":"TOYDARIAN","img":"https://i.imgur.com/yUP0vnb.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Toydarian","obscurity":2},
{"name":"TRANDOSHAN","img":"https://i.imgur.com/4nfST5s.png","appearance":5,"link":"https://starwars.fandom.com/wiki/Trandoshan","obscurity":2},
{"name":"TRIANII","img":"https://i.imgur.com/tjdNAx6.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Trianii","obscurity":6},
{"name":"TRIFFIAN","img":"https://i.imgur.com/MOAVM7C.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Triffian","obscurity":4},
{"name":"TRODATOME","img":"https://i.imgur.com/o6BSToA.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Trodatome","obscurity":4},
{"name":"TROGLOF","img":"https://i.imgur.com/7zVBevW.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Troglof","obscurity":4},
{"name":"TROIG","img":"https://i.imgur.com/okqrvOh.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Troig","obscurity":4},
{"name":"TUSKEN RAIDER","img":"https://i.imgur.com/IHEdMaM.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Tusken Raider","obscurity":1},
{"name":"TWI'LEK","img":"https://i.imgur.com/zPzfEpq.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Twilek","obscurity":2},
{"name":"TYNNAN","img":"https://i.imgur.com/v7e0NA5.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Tynnan","obscurity":4},
{"name":"UBDURIAN","img":"https://i.imgur.com/cz3CcTy.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Ubdurian","obscurity":4},
{"name":"UBESE","img":"https://i.imgur.com/AtbbpbQ.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Ubese","obscurity":4},
{"name":"UGNAUGHT","img":"https://i.imgur.com/xYtLiK1.png","appearance":5,"link":"https://starwars.fandom.com/wiki/Ugnaught","obscurity":2},
{"name":"UGOR","img":"https://i.imgur.com/wrAca1F.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Ugor","obscurity":4},
{"name":"UMBARAN","img":"https://i.imgur.com/nasjHHZ.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Umbaran","obscurity":4},
{"name":"UNGRILLA","img":"https://i.imgur.com/lt5dQvR.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Ungrilla","obscurity":4},
{"name":"URODEL","img":"https://i.imgur.com/iAC4Lzy.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Urodel","obscurity":4},
{"name":"UTAI","img":"https://i.imgur.com/Ph4YJP3.png","appearance":3,"link":"https://starwars.fandom.com/wiki/Utai","obscurity":4},
{"name":"VAGAARI","img":"https://i.imgur.com/kVUlNyF.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Vagaari","obscurity":5},
{"name":"VEKNOID","img":"https://i.imgur.com/YpF8ZkJ.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Veknoid","obscurity":4},
{"name":"VERPINE","img":"https://i.imgur.com/3xZVyGg.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Verpine","obscurity":5},
{"name":"VILLARANDI","img":"https://i.imgur.com/EsA6pue.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Villarandi","obscurity":5},
{"name":"VINTIAN","img":"https://i.imgur.com/P7qQcJg.png","appearance":17,"link":"https://starwars.fandom.com/wiki/Vintian","obscurity":5},
{"name":"VOBATI","img":"https://i.imgur.com/J19bEeU.png","appearance":10,"link":"https://starwars.fandom.com/wiki/Vobati","obscurity":4},
{"name":"VODRAN","img":"https://i.imgur.com/Twuyu3J.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Vodran","obscurity":5},
{"name":"VOLLICK","img":"https://i.imgur.com/PXNAUVv.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Vollick","obscurity":6},
{"name":"VOLPAI","img":"https://i.imgur.com/dQAT3qC.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Volpai","obscurity":4},
{"name":"VONOMI","img":"https://i.imgur.com/vCfUlMr.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Vonomi","obscurity":4},
{"name":"VRATIX","img":"https://i.imgur.com/JeJ8XEv.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Vratix","obscurity":6},
{"name":"VULPTEREEN","img":"https://i.imgur.com/9A5Ll6i.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Vulptereen","obscurity":4},
{"name":"VURK","img":"https://i.imgur.com/clVzsNi.png","appearance":2,"link":"https://starwars.fandom.com/wiki/Vurk","obscurity":4},
{"name":"VUVRIAN","img":"https://i.imgur.com/o4nS4uD.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Vuvrian","obscurity":4},
{"name":"WALUNA","img":"https://i.imgur.com/WLcqulJ.png","appearance":15,"link":"https://starwars.fandom.com/wiki/Waluna","obscurity":4},
{"name":"WARTHOG","img":"https://i.imgur.com/qQeu7ol.png","appearance":16,"link":"https://starwars.fandom.com/wiki/Warthog","obscurity":4},
{"name":"WAYUNING","img":"https://i.imgur.com/euuX3Gw.png","appearance":9,"link":"https://starwars.fandom.com/wiki/Wayuning","obscurity":4},
{"name":"WEEQUAY","img":"https://i.imgur.com/6sWUiVt.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Weequay","obscurity":3},
{"name":"WERMAL","img":"https://i.imgur.com/GtqAOHz.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Wermal","obscurity":4},
{"name":"WHALADON","img":"https://i.imgur.com/6FzSILF.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Whaladon","obscurity":6},
{"name":"WHIPHID","img":"https://i.imgur.com/07F0THL.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Whiphid","obscurity":4},
{"name":"WHIPPOMORN","img":"https://i.imgur.com/pnfN6n4.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Whippomorn","obscurity":4},
{"name":"WICKWAR","img":"https://i.imgur.com/phvYslH.png","appearance":11,"link":"https://starwars.fandom.com/wiki/Wickwar","obscurity":4},
{"name":"WISTE","img":"https://i.imgur.com/rInJMAg.png","appearance":15,"link":"https://starwars.fandom.com/wiki/Wiste","obscurity":6},
{"name":"WOL CABASSHITE","img":"https://i.imgur.com/P01ODw8.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Wol Cabasshite","obscurity":4},
{"name":"WOOKIE","img":"https://i.imgur.com/FKUuDTv.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Wookie","obscurity":1},
{"name":"WOOSTROID","img":"https://i.imgur.com/gyvTAeK.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Woostroid","obscurity":6},
{"name":"WROONIAN","img":"https://i.imgur.com/7I5atz6.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Wroonian","obscurity":6},
{"name":"XAMSTER","img":"https://i.imgur.com/UCCnCIN.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Xamster","obscurity":4},
{"name":"XAN","img":"https://i.imgur.com/fAT93EO.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Xan","obscurity":6},
{"name":"XENDEKIAN","img":"https://i.imgur.com/mtsaYm7.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Xendekian","obscurity":6},
{"name":"XEXTO","img":"https://i.imgur.com/upaGtsi.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Xexto","obscurity":4},
{"name":"XI'DEC","img":"https://i.imgur.com/1AocTTb.png","appearance":8,"link":"https://starwars.fandom.com/wiki/Xidec","obscurity":4},
{"name":"XIMPI","img":"https://i.imgur.com/NRzpMrU.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Ximpi","obscurity":5},
{"name":"XOT","img":"https://i.imgur.com/Dhk9ykm.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Xot","obscurity":5},
{"name":"YAM'RII","img":"https://i.imgur.com/7DcQhYK.png","appearance":4,"link":"https://starwars.fandom.com/wiki/Yamrii","obscurity":4},
{"name":"YARKORA","img":"https://i.imgur.com/KKriyqr.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Yarkora","obscurity":4},
{"name":"YINCHORRI","img":"https://i.imgur.com/0GLKD8E.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Yinchorri","obscurity":6},
{"name":"YODA'S SPECIES","img":"https://i.imgur.com/ZoGJcio.png","appearance":5,"link":"https://starwars.fandom.com/wiki/Yoda%27s_species","obscurity":1},
{"name":"YSANNA","img":"https://i.imgur.com/XIpUlVL.png","appearance":19,"link":"https://starwars.fandom.com/wiki/Ysanna","obscurity":6},
{"name":"YUVERNIAN","img":"https://i.imgur.com/AsEKOiz.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Yuvernian","obscurity":4},
{"name":"YUZZUM","img":"https://i.imgur.com/wgd1C8q.png","appearance":6,"link":"https://starwars.fandom.com/wiki/Yuzzum","obscurity":4},
{"name":"ZABRAK","img":"https://i.imgur.com/WzAZIvN.png","appearance":1,"link":"https://starwars.fandom.com/wiki/Zabrak","obscurity":2},
{"name":"ZEFFO","img":"https://i.imgur.com/CYNPo9b.png","appearance":15,"link":"https://starwars.fandom.com/wiki/Zeffo","obscurity":5},
{"name":"ZELTRON","img":"https://i.imgur.com/JDyAT5o.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Zeltron","obscurity":5},
{"name":"ZENEZIAN","img":"https://i.imgur.com/zVwQ3ya.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Zenezian","obscurity":4},
{"name":"ZEUOL","img":"https://i.imgur.com/fzRupPf.png","appearance":3,"link":"https://starwars.fandom.com/wiki/Zeuol","obscurity":4},
{"name":"ZILKIN","img":"https://i.imgur.com/8FF3EYz.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Zilkin","obscurity":4},
{"name":"ZIXON","img":"https://i.imgur.com/N1yB5w5.png","appearance":18,"link":"https://starwars.fandom.com/wiki/Zixon","obscurity":6},
{"name":"ZUZABOL","img":"https://i.imgur.com/tFT9jHw.png","appearance":7,"link":"https://starwars.fandom.com/wiki/Zuzabol","obscurity":4},
{"name":"ZYGERRIAN","img":"https://i.imgur.com/GsG2agQ.png","appearance":12,"link":"https://starwars.fandom.com/wiki/Zygerrian","obscurity":4}];
